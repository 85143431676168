<template>
  <div class="main" data-title="任务列表" v-title>
    <div class="page-header">
      <div class="page-header-body">
        <h2 class="page-header-title">任务列表</h2>
      </div>
      <div class="head-search"></div>
      <div class="head-tabs">
        <div
          @click="changeType('taskTel')"
          :class="{ curr: taskType == 'taskTel' }"
          class="tab"
        >
          回访任务
        </div>
        <div
          @click="changeType('taskIns')"
          :class="{ curr: taskType == 'taskIns' }"
          class="tab"
        >
          检核任务
        </div>
        <div
          @click="changeType('taskConf')"
          :class="{ curr: taskType == 'taskConf' }"
          class="tab"
        >
          审核任务
        </div>
      </div>
    </div>

    <el-card class="main-content" shadow="hover" v-if="taskType == 'taskTel'">
      <div class="jue-card-header">
        <div class="jue-card-title">回访任务</div>
        <div class="jue-card-btns">
          <el-select
            clearable
            filterable
            placeholder="请选择销售顾问"
            v-model="searchKey.task_uid"
            @change="changeUid"
          >
            <template v-for="(item, key) in $store.state.userList">
              <el-option
                :key="key"
                :label="item[0]"
                :value="parseInt(key)"
                v-if="item[3] == 1"
              ></el-option>
            </template>
          </el-select>
          <el-button
            class="btn"
            type="primary"
            icon="el-icon-plus"
            @click="setTask"
            >设置回访任务</el-button>
        </div>
      </div>
      <div class="table-wrapper">
        <el-table
          :data="taskTelData"
          :style="'width: ' + $store.state.tableWidth"
          class="table"
        >
          <!-- <el-table-column type="selection" width="55"></el-table-column> -->
          <el-table-column label="员工" prop="task_type" show-overflow-tooltip>
            <template
              slot-scope="scope"
              v-if="$store.state.userList[scope.row.task_uid] != undefined"
              >{{ $store.state.userList[scope.row.task_uid][0] }}</template
            >
          </el-table-column>
          <el-table-column label="电话量" prop="quantity" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.quantity }} /天</template>
          </el-table-column>
          <el-table-column label="任务周期" show-overflow-tooltip>
            <template slot-scope="scope"
              >{{ scope.row.begin_date }} ~ {{ scope.row.end_date }}</template
            >
          </el-table-column>
          <el-table-column label="今日完成量">
            <template slot-scope="scope">{{
              scope.row.today_finished_quantity
            }}</template>
          </el-table-column>
          <el-table-column label="累计完成量">
            <template slot-scope="scope">{{
              scope.row.finished_quantity
            }}</template>
          </el-table-column>
          <el-table-column label="备注" prop="memo"></el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="100px"
            v-if="getAuth('add')"
          >
            <template slot-scope="scope">
              <el-button
                @click="edit(scope.row)"
                size="small"
                type="text"
                v-has="'task-edit'"
                >修改</el-button
              >
              <span class="jue-divider" v-has="'task-edit'"></span>
              <el-button
                @click="deleteItem(scope.row)"
                class="btn-delete no-bg"
                size="small"
                type="text"
                v-has="'task-remove'"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="footer">
        <el-pagination
          :page-size="searchKey.count"
          :total="total"
          @current-change="handleCurrentChange"
          background
          layout="prev, pager, next"
        ></el-pagination>
      </div>
    </el-card>

    <el-card
      class="main-content"
      shadow="hover"
      v-has="'trace-check'"
      v-if="taskType == 'taskIns'"
    >
      <div class="jue-card-header">
        <div class="jue-card-title">检核任务</div>
        <div class="jue-card-btns">
          <el-button
            class="btn"
            type="primary"
            icon="el-icon-plus"
            @click="setTask"
            >设置检核任务</el-button>
        </div>
      </div>
      <div class="table-wrapper">
        <el-table
          :data="taskInsData"
          :style="'width: ' + $store.state.tableWidth"
          class="table"
        >
          <!-- <el-table-column type="selection" width="55"></el-table-column> -->
          <el-table-column label="检核人" show-overflow-tooltip>
            <template
              slot-scope="scope"
              v-if="$store.state.userList[scope.row.task_uid] != undefined"
              >{{ $store.state.userList[scope.row.task_uid][0] }}</template
            >
          </el-table-column>
          <el-table-column
            label="检核数量"
            prop="quantity"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{ scope.row.quantity }} 条</template>
          </el-table-column>
          <el-table-column label="检核周期" show-overflow-tooltip>
            <template slot-scope="scope"
              >{{ scope.row.begin_date }} ~ {{ scope.row.end_date }}</template
            >
          </el-table-column>
          <el-table-column label="今日完成量">
            <template slot-scope="scope">{{
              scope.row.today_finished_quantity
            }}</template>
          </el-table-column>
          <el-table-column label="累计完成量">
            <template slot-scope="scope">{{
              scope.row.finished_quantity
            }}</template>
          </el-table-column>
          <el-table-column label="备注" prop="memo"></el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="100px"
            v-if="getAuth('add')"
          >
            <template slot-scope="scope">
              <el-button
                @click="edit(scope.row)"
                size="small"
                type="text"
                v-has="'task-edit'"
                >修改</el-button
              >
              <span class="jue-divider" v-has="'task-edit'"></span>
              <el-button
                @click="deleteItem(scope.row)"
                class="btn-delete no-bg"
                size="small"
                type="text"
                v-has="'task-remove'"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="footer">
        <el-pagination
          :page-size="searchKey.count"
          :total="total"
          @current-change="handleCurrentChange"
          background
          layout="prev, pager, next"
        ></el-pagination>
      </div>
    </el-card>

    <el-card
      class="main-content"
      shadow="hover"
      v-has="'trace-confirm_lists'"
      v-if="taskType == 'taskConf'"
    >
      <div class="jue-card-header">
        <div class="jue-card-title">审核任务</div>
        <div class="jue-card-btns">
          <el-select
            clearable
            filterable
            placeholder="请选择销售顾问"
            v-model="searchKey.create_uid"
            @change="changeUid"
          >
            <template v-for="(item, key) in $store.state.userList">
              <el-option
                :key="key"
                :label="item[0]"
                :value="parseInt(key)"
                v-if="item[3] == 1"
              ></el-option>
            </template>
          </el-select>
          <el-button
            class="btn"
            type="primary"
            icon="el-icon-plus"
            @click="setTask"
            >设置回访任务</el-button>
        </div>
      </div>
      <div class="table-wrapper">
        <el-table
          :data="taskConfData"
          :style="'width: ' + $store.state.tableWidth"
          class="table"
        >
          <!-- <el-table-column type="selection" width="55"></el-table-column> -->
          <el-table-column label="状态" show-overflow-tooltip>
            <template slot-scope="scope">{{
              getStatus(scope.row.new_val)
            }}</template>
          </el-table-column>
          <el-table-column
            label="销售顾问"
            prop="quantity"
            show-overflow-tooltip
          >
            <template
              slot-scope="scope"
              v-if="
                $store.state.userList[scope.row.trace.seller_uid] != undefined
              "
              >{{
                $store.state.userList[scope.row.trace.seller_uid][0]
              }}</template
            >
          </el-table-column>
          <el-table-column label="客户姓名">
            <template slot-scope="scope">{{
              trackList[scope.row.client_id]
            }}</template>
          </el-table-column>
          <el-table-column label="联系方式">
            <template slot-scope="scope">{{ scope.row.trace.mobile }}</template>
          </el-table-column>
          <el-table-column label="最近跟踪日期">
            <template slot-scope="scope">{{
              scope.row.trace.trace_date
            }}</template>
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="120px"
            v-if="getAuth('confirm')"
          >
            <template slot-scope="scope">
              <el-button
                @click="
                  $router.push({
                    name: 'customer-confirm',
                    query: {
                      trace_id: scope.row.trace_id,
                      sys_id: scope.row.sys_id,
                    },
                  })
                "
                size="small"
                type="text"
                v-has="'trace-confirm'"
                >查看并审核</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="footer">
        <el-pagination
          :page-size="searchKey.count"
          :total="total"
          @current-change="handleCurrentChange"
          background
          layout="prev, pager, next"
        ></el-pagination>
      </div>
    </el-card>

    <edit-task ref="task"></edit-task>
  </div>
</template>
<script>
import EditTask from '@/components/EditTask.vue'
export default {
  components: {
    EditTask
  },
  data () {
    return {
      taskType: 'taskTel',
      trackList: {},
      taskTelData: [],
      taskInsData: [],
      taskConfData: [],
      total: 0,
      searchKey: {
        create_uid: '',
        confirm_status: 0,
        page: 1,
        count: 15
      }
    }
  },
  mounted () {
    this.getTaskData()
    this.getTrackList()
  },
  methods: {
    setTask () {
      let data = {
        task_type: this.taskType == 'taskTel' ? 1 : 2,
        editType: 'create'
      }
      this.$refs.task.edit(data)
    },
    changeUid () {
      if (this.taskType == 'taskConf') {
        this.getConfData()
      } else {
        this.getTaskData()
      }
    },
    changeType (taskType) {
      this.taskType = taskType
      this.total = 0
      this.searchKey.page = 1
      if (this.taskType == 'taskConf') {
        this.getConfData()
      }
    },
    getAuth (str) {
      let pa = this.$route.path.split('/')
      if (this.$store.state.auth[pa[1]][pa[2]][str] == undefined) {
        return false
      } else {
        return true
      }
    },
    showConf (data) {
      this.$refs.conf.show(data)
    },
    getTrackList () {
      this.$axios.post('/index/client').then(res => {
        this.trackList = res.data.data.list
      })
    },
    getStatus (val) {
      let txt = ''
      let arr = val.split('|')
      arr.map(item => {
        if (item == 22) {
          txt += ' 战败 '
        }
        if (item == 33) {
          txt += ' 失控 '
        }
      })

      return txt
    },
    getConfData () {
      delete this.searchKey.task_uid
      if(this.searchKey.create_uid == ''){
        delete this.searchKey.create_uid
      }
      this.$axios.post('/trace/confirm_lists', this.searchKey).then(res => {
        if (res.data.code == 200) {
          this.total = res.data.data.total
          this.taskConfData = res.data.data.list
        }
      })
    },
    edit (data) {
      this.$refs.task.edit(data)
    },
    deleteItem (item) {
      this.$confirm('此操作将永久删除本任务 , 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$axios
            .post('/task/remove', {
              task_id: item.task_id,
              task_uid: item.task_uid
            })
            .then(res => {
              if (res.data.code == 200) {
                this.$message.success(res.data.msg)
                this.taskTelData = []
                this.taskInsData = []
                this.getTaskData()
              } else {
                this.$message.error(res.data.msg)
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    getTaskData () {
      if (!this.searchKey.task_uid) {
        delete this.searchKey.task_uid
        delete this.searchKey.confirm_status
      }
      this.$axios.post('/task/lists', this.searchKey).then(res => {
        if (res.data.code == 200) {
          this.taskData = res.data.data.list
          this.total = res.data.data.total
          this.taskTelData = []
          this.taskInsData = []
          res.data.data.list.map(item => {
            if (item.task_type == 1) {
              this.taskTelData.push(item)
            }
            if (item.task_type == 2) {
              this.taskInsData.push(item)
            }
          })
        }
      })
    },
    handleCurrentChange (val) {
      this.searchKey.page = val
      if (this.taskType == 'taskConf') {
        this.getConfData()
      } else {
        this.getTaskData()
      }
    }
  }
}
</script>
<style lang="less" scoped>
.table-wrapper {
  margin: -20px -20px 0;
}

.footer {
  text-align: right;
  padding: 20px 0;
}
</style>