<template>
  <el-drawer :visible.sync="visible" :with-header="false" size="600px">
    <div class="jue-drawer-header">
      <div class="jue-drawer-title">
        {{ editType == 'create' ? '创建' : '编辑' }}任务
      </div>
      <div class="jue-drawer-btns">
        <i @click="visible = false" class="el-icon-close"></i>
      </div>
    </div>

    <div class="jue-drawer-body">
      <el-row :gutter="20">
        <el-col :span="12" v-if="editType == 'edit'">
          <el-form class="form" label-position="right" label-width="120px">
            <el-form-item
              label="员工姓名: "
              v-if="$store.state.userList[info.task_uid] != undefined"
              >{{ $store.state.userList[info.task_uid][0] }}</el-form-item
            >
          </el-form>
        </el-col>
        <el-col :span="12" v-if="editType == 'create'">
          <el-form class="form" label-position="right" label-width="120px">
            <el-form-item label="员工姓名: ">
              <el-select
                clearable
                filterable
                placeholder="请选择员工"
                v-model="info.task_uid"
              >
                <template v-for="(item, key) in $store.state.userList">
                  <el-option
                    :key="key"
                    :label="item[0] + (item[3] == 1 ? ' - 销售顾问' : '')"
                    :value="parseInt(key)"
                    v-if="item[2] == 1 && item[1] != 1"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <!-- <el-col :span="12" v-if="editType == 'create' && type == '检核' ">
          <el-form class="form" label-position="right" label-width="120px">
            <el-form-item label="员工姓名: ">这是编辑有检核</el-form-item>
          </el-form>
        </el-col> -->
        <!-- <el-col :span="12">
          <el-form class="form" label-position="right" label-width="120px">
            <el-form-item label="职务:">{{getRole(info.role).label}}</el-form-item>
          </el-form>
        </el-col>-->
      </el-row>

      <el-divider content-position="left">{{ type }}任务</el-divider>
      <el-form
        :model="form"
        :rules="rules"
        class="form"
        label-position="right"
        label-width="120px"
        ref="form"
      >
        <el-form-item :label="type + '量:'" prop="quantity">
          <el-input-number
            :max="100"
            :min="0"
            controls-position="right"
            v-model="form.quantity"
          ></el-input-number>
          <span style="margin-left: 20px">{{ unit }}</span>
        </el-form-item>
        <el-form-item label="任务周期: " prop="formDate">
          <el-date-picker
            end-placeholder="结束日期"
            range-separator="-"
            start-placeholder="开始日期"
            type="daterange"
            v-model="form.formDate"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="备注: ">
          <el-input
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="请输入内容"
            type="textarea"
            v-model="form.memo"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>

    <div class="form-footer">
      <div>
        <el-button @click="resetForm('form')">取 消</el-button>
        <el-button @click="submitForm('form')" type="primary">提 交</el-button>
      </div>
    </div>
  </el-drawer>
</template>
<script>
import { roleByID } from '@/unit/unit.js'
export default {
  data () {
    var checkNum = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('任务量不能为0 或 空'))
      }
      callback()
    }
    return {
      visible: false,
      editType: 'edit',
      task_id: null,
      type: '', // 电话   检核
      unit: '', //天   条
      info: {
        task_uid: ''
      },
      form: {
        task_type: 0,
        formDate: [],
        quantity: '0',
        memo: ''
      },
      rules: {
        // quantity: [{ required: true, validator: checkNum, trigger: 'blur' }],
        quantity: [{ required: true, validator: checkNum, trigger: 'change' }],
        formDate: [{ required: true, message: '必需填写', trigger: 'change' }]
      }
    }
  },
  mounted () {
    this.setTime()
  },
  methods: {
    setTime () {
      var date = new Date(), y = date.getFullYear(), m = date.getMonth();
      this.form.formDate = [this.$moment(new Date(y, m, 1)).format('YYYY-MM-DD'), this.$moment(new Date(y, m + 1, 0)).format('YYYY-MM-DD')]
    },
    edit (data) {
      this.visible = true
      if (data.task_type == 1) {
        this.type = '电话'
        this.unit = '/天'
      }
      if (data.task_type == 2) {
        this.type = '检核'
        this.unit = '条'
      }
      this.form.task_type = data.task_type

      this.editType = data.editType == 'create' ? 'create' : 'edit'

      if (this.editType == 'edit') {
        this.info.task_uid = data.task_uid
        this.info.task_id = data.task_id

        this.form.formDate = [data.begin_date, data.end_date]
        this.form.quantity = data.quantity
        this.form.memo = data.memo
      } else {
        this.setTime()
      }


    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
      this.visible = false
    },
    submitForm (formName) {
      let _this = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let sub_data = {
            task_uid: this.info.task_uid,
            task_type: this.form.task_type,
            begin_date: this.form.formDate[0],
            end_date: this.form.formDate[1],
            quantity: this.form.quantity,
            memo: this.form.memo
          }
          if (this.editType == 'edit') {
            sub_data.task_id = this.info.task_id
          }

          this.$axios
            .post('/task/' + _this.editType, sub_data)
            .then(res => {
              if (res.data.code == 200) {
                this.$message.success(res.data.msg)
                this.$parent.taskTelData = []
                this.$parent.taskInsData = []
                this.$parent.getTaskData()
                this.resetForm('form')
              }
            })
        }
      })
    },
    getRole (id) {
      if (id) {
        return roleByID(id)
      } else {
        return {
          label: ''
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.jue-drawer-body {
  padding: 0 40px;
}

.w100 {
  width: 100%;
}

.form {
  padding: 20px 0;
}

.form-footer {
  text-align: right;
  width: 600px;
  padding: 0 40px 0 0;
  el-button {
    margin-left: 20px;
  }
}
</style>